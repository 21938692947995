import React from 'react'

export default function Testimonial() {
    return (
        <div className='padding-global padding-section-large'>
            <h2 class="heading-v1">Here’s what our Current Students &nbsp;have to say...</h2>

            <div className='d-flex align-items-center justify-content-between results_card-wrapper'>
                <div className='mx-2 d-flex flex-column'>
                    <div className='results_card secondary'>
                        <div class="progress">
                            <span class="title timer" data-from="0" data-to="85" data-speed="1800">85%</span>
                            <div class="overlay"></div>
                            <div class="left"></div>
                            <div class="right"></div>
                        </div>

                        <p class="text-color-white titlium-font">of customers were served successfully*</p>
                    </div>

                    <div className='results_card'>
                        <div class="w-embed">
                            <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 17.3245C0 21.1391 2.9404 24 6.6755 24C10.4106 24 13.351 21.1391 13.351 17.3245C13.351 14.702 11.9205 12.4768 9.77484 11.4437L14.2252 0H7.94702L2.06623 11.4437C0.715232 14.0662 0 15.7351 0 17.3245ZM17.0066 17.3245C17.0066 21.1391 19.947 24 23.6821 24C27.4967 24 30.3576 21.1391 30.3576 17.3245C30.3576 14.702 28.9272 12.4768 26.7815 11.4437L31.2318 0H24.9536L19.0729 11.4437C17.7219 14.0662 17.0066 15.7351 17.0066 17.3245Z" fill="#97532e"></path>
                            </svg>
                        </div>
                        <p>
                            So the course is just as great as dhruvee rather himself, I like how the way he explain the things by showing his own life experiences and yeah that will help me a lot for sure, mainly the day 3 and 4 part will help me beat the procrastination
                        </p>

                        <div className='d-flex align-items-center'>
                            <div>
                                <p className='text-weight-bold text-size-medium'>
                                    Hardeep S.
                                </p>

                                <div class="w-embed">
                                    <svg width="76" height="12" viewBox="0 0 76 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.8361 4.42891C11.7584 4.20096 11.5453 4.03906 11.2931 4.0175L7.86797 3.72247L6.51357 0.715227C6.41371 0.494835 6.18627 0.352173 5.93357 0.352173C5.68087 0.352173 5.45343 0.494835 5.35357 0.715743L3.99917 3.72247L0.573482 4.0175C0.321779 4.03958 0.10919 4.20096 0.0310539 4.42891C-0.0470826 4.65686 0.0250782 4.90689 0.215485 5.06449L2.80449 7.21843L2.04105 10.4086C1.98519 10.6432 2.08116 10.8856 2.28633 11.0263C2.39661 11.1019 2.52563 11.1404 2.65573 11.1404C2.76791 11.1404 2.87919 11.1117 2.97905 11.055L5.93357 9.37992L8.887 11.055C9.10312 11.1784 9.37556 11.1671 9.58027 11.0263C9.78553 10.8852 9.88141 10.6427 9.82554 10.4086L9.06211 7.21843L11.6511 5.06492C11.8415 4.90689 11.9142 4.65729 11.8361 4.42891Z" fill="#FEC031"></path>
                                        <path d="M27.6584 4.42879C27.5807 4.20084 27.3675 4.03894 27.1154 4.01738L23.6902 3.72235L22.3358 0.715105C22.236 0.494713 22.0085 0.352051 21.7558 0.352051C21.5031 0.352051 21.2757 0.494713 21.1758 0.715621L19.8214 3.72235L16.3957 4.01738C16.144 4.03945 15.9315 4.20084 15.8533 4.42879C15.7752 4.65674 15.8473 4.90676 16.0378 5.06437L18.6268 7.21831L17.8633 10.4085C17.8075 10.6431 17.9034 10.8855 18.1086 11.0262C18.2189 11.1018 18.3479 11.1403 18.478 11.1403C18.5902 11.1403 18.7015 11.1116 18.8013 11.0549L21.7558 9.3798L24.7093 11.0549C24.9254 11.1782 25.1978 11.167 25.4025 11.0262C25.6078 10.8851 25.7037 10.6425 25.6478 10.4085L24.8844 7.21831L27.4734 5.0648C27.6638 4.90676 27.7365 4.65717 27.6584 4.42879Z" fill="#FEC031"></path>
                                        <path d="M43.4816 4.42879C43.4039 4.20084 43.1908 4.03894 42.9386 4.01738L39.5135 3.72235L38.1591 0.715105C38.0592 0.494713 37.8318 0.352051 37.5791 0.352051C37.3264 0.352051 37.0989 0.494713 36.9991 0.715621L35.6447 3.72235L32.219 4.01738C31.9673 4.03945 31.7547 4.20084 31.6766 4.42879C31.5984 4.65674 31.6706 4.90676 31.861 5.06437L34.45 7.21831L33.6866 10.4085C33.6307 10.6431 33.7267 10.8855 33.9318 11.0262C34.0421 11.1018 34.1711 11.1403 34.3012 11.1403C34.4134 11.1403 34.5247 11.1116 34.6246 11.0549L37.5791 9.3798L40.5325 11.0549C40.7486 11.1782 41.0211 11.167 41.2258 11.0262C41.431 10.8851 41.5269 10.6425 41.4711 10.4085L40.7076 7.21831L43.2966 5.0648C43.487 4.90676 43.5597 4.65717 43.4816 4.42879Z" fill="#FEC031"></path>
                                        <path d="M59.3039 4.42879C59.2262 4.20084 59.013 4.03894 58.7609 4.01738L55.3357 3.72235L53.9813 0.715105C53.8815 0.494713 53.654 0.352051 53.4013 0.352051C53.1486 0.352051 52.9212 0.494713 52.8213 0.715621L51.4669 3.72235L48.0413 4.01738C47.7896 4.03945 47.577 4.20084 47.4988 4.42879C47.4207 4.65674 47.4929 4.90676 47.6833 5.06437L50.2723 7.21831L49.5088 10.4085C49.453 10.6431 49.5489 10.8855 49.7541 11.0262C49.8644 11.1018 49.9934 11.1403 50.1235 11.1403C50.2357 11.1403 50.347 11.1116 50.4468 11.0549L53.4013 9.3798L56.3548 11.0549C56.5709 11.1782 56.8433 11.167 57.048 11.0262C57.2533 10.8851 57.3492 10.6425 57.2933 10.4085L56.5299 7.21831L59.1189 5.0648C59.3093 4.90676 59.382 4.65717 59.3039 4.42879Z" fill="#FEC031"></path>
                                        <path d="M75.1271 4.42879C75.0494 4.20084 74.8363 4.03894 74.5841 4.01738L71.159 3.72235L69.8046 0.715105C69.7047 0.494713 69.4773 0.352051 69.2246 0.352051C68.9719 0.352051 68.7444 0.494713 68.6446 0.715621L67.2902 3.72235L63.8645 4.01738C63.6128 4.03945 63.4002 4.20084 63.3221 4.42879C63.2439 4.65674 63.3161 4.90676 63.5065 5.06437L66.0955 7.21831L65.3321 10.4085C65.2762 10.6431 65.3722 10.8855 65.5773 11.0262C65.6876 11.1018 65.8166 11.1403 65.9467 11.1403C66.0589 11.1403 66.1702 11.1116 66.2701 11.0549L69.2246 9.3798L72.178 11.0549C72.3941 11.1782 72.6666 11.167 72.8713 11.0262C73.0765 10.8851 73.1724 10.6425 73.1166 10.4085L72.3531 7.21831L74.9421 5.0648C75.1325 4.90676 75.2052 4.65717 75.1271 4.42879Z" fill="#FEC031"></path>
                                    </svg>
                                </div>
                            </div>

                            <div className='mx-2'>
                                <img src={require('./Assets/Sailor.png')} className='review_img' />
                            </div>
                        </div>
                    </div>

                    <div className='results_card'>
                        <div class="w-embed">
                            <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 17.3245C0 21.1391 2.9404 24 6.6755 24C10.4106 24 13.351 21.1391 13.351 17.3245C13.351 14.702 11.9205 12.4768 9.77484 11.4437L14.2252 0H7.94702L2.06623 11.4437C0.715232 14.0662 0 15.7351 0 17.3245ZM17.0066 17.3245C17.0066 21.1391 19.947 24 23.6821 24C27.4967 24 30.3576 21.1391 30.3576 17.3245C30.3576 14.702 28.9272 12.4768 26.7815 11.4437L31.2318 0H24.9536L19.0729 11.4437C17.7219 14.0662 17.0066 15.7351 17.0066 17.3245Z" fill="#97532e"></path>
                            </svg>
                        </div>
                        <p>
                            So the course is just as great as dhruvee rather himself, I like how the way he explain the things by showing his own life experiences and yeah that will help me a lot for sure, mainly the day 3 and 4 part will help me beat the procrastination
                        </p>

                        <div className='d-flex align-items-center'>
                            <div>
                                <p className='text-weight-bold text-size-medium'>
                                    Hardeep S.
                                </p>

                                <div class="w-embed">
                                    <svg width="76" height="12" viewBox="0 0 76 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.8361 4.42891C11.7584 4.20096 11.5453 4.03906 11.2931 4.0175L7.86797 3.72247L6.51357 0.715227C6.41371 0.494835 6.18627 0.352173 5.93357 0.352173C5.68087 0.352173 5.45343 0.494835 5.35357 0.715743L3.99917 3.72247L0.573482 4.0175C0.321779 4.03958 0.10919 4.20096 0.0310539 4.42891C-0.0470826 4.65686 0.0250782 4.90689 0.215485 5.06449L2.80449 7.21843L2.04105 10.4086C1.98519 10.6432 2.08116 10.8856 2.28633 11.0263C2.39661 11.1019 2.52563 11.1404 2.65573 11.1404C2.76791 11.1404 2.87919 11.1117 2.97905 11.055L5.93357 9.37992L8.887 11.055C9.10312 11.1784 9.37556 11.1671 9.58027 11.0263C9.78553 10.8852 9.88141 10.6427 9.82554 10.4086L9.06211 7.21843L11.6511 5.06492C11.8415 4.90689 11.9142 4.65729 11.8361 4.42891Z" fill="#FEC031"></path>
                                        <path d="M27.6584 4.42879C27.5807 4.20084 27.3675 4.03894 27.1154 4.01738L23.6902 3.72235L22.3358 0.715105C22.236 0.494713 22.0085 0.352051 21.7558 0.352051C21.5031 0.352051 21.2757 0.494713 21.1758 0.715621L19.8214 3.72235L16.3957 4.01738C16.144 4.03945 15.9315 4.20084 15.8533 4.42879C15.7752 4.65674 15.8473 4.90676 16.0378 5.06437L18.6268 7.21831L17.8633 10.4085C17.8075 10.6431 17.9034 10.8855 18.1086 11.0262C18.2189 11.1018 18.3479 11.1403 18.478 11.1403C18.5902 11.1403 18.7015 11.1116 18.8013 11.0549L21.7558 9.3798L24.7093 11.0549C24.9254 11.1782 25.1978 11.167 25.4025 11.0262C25.6078 10.8851 25.7037 10.6425 25.6478 10.4085L24.8844 7.21831L27.4734 5.0648C27.6638 4.90676 27.7365 4.65717 27.6584 4.42879Z" fill="#FEC031"></path>
                                        <path d="M43.4816 4.42879C43.4039 4.20084 43.1908 4.03894 42.9386 4.01738L39.5135 3.72235L38.1591 0.715105C38.0592 0.494713 37.8318 0.352051 37.5791 0.352051C37.3264 0.352051 37.0989 0.494713 36.9991 0.715621L35.6447 3.72235L32.219 4.01738C31.9673 4.03945 31.7547 4.20084 31.6766 4.42879C31.5984 4.65674 31.6706 4.90676 31.861 5.06437L34.45 7.21831L33.6866 10.4085C33.6307 10.6431 33.7267 10.8855 33.9318 11.0262C34.0421 11.1018 34.1711 11.1403 34.3012 11.1403C34.4134 11.1403 34.5247 11.1116 34.6246 11.0549L37.5791 9.3798L40.5325 11.0549C40.7486 11.1782 41.0211 11.167 41.2258 11.0262C41.431 10.8851 41.5269 10.6425 41.4711 10.4085L40.7076 7.21831L43.2966 5.0648C43.487 4.90676 43.5597 4.65717 43.4816 4.42879Z" fill="#FEC031"></path>
                                        <path d="M59.3039 4.42879C59.2262 4.20084 59.013 4.03894 58.7609 4.01738L55.3357 3.72235L53.9813 0.715105C53.8815 0.494713 53.654 0.352051 53.4013 0.352051C53.1486 0.352051 52.9212 0.494713 52.8213 0.715621L51.4669 3.72235L48.0413 4.01738C47.7896 4.03945 47.577 4.20084 47.4988 4.42879C47.4207 4.65674 47.4929 4.90676 47.6833 5.06437L50.2723 7.21831L49.5088 10.4085C49.453 10.6431 49.5489 10.8855 49.7541 11.0262C49.8644 11.1018 49.9934 11.1403 50.1235 11.1403C50.2357 11.1403 50.347 11.1116 50.4468 11.0549L53.4013 9.3798L56.3548 11.0549C56.5709 11.1782 56.8433 11.167 57.048 11.0262C57.2533 10.8851 57.3492 10.6425 57.2933 10.4085L56.5299 7.21831L59.1189 5.0648C59.3093 4.90676 59.382 4.65717 59.3039 4.42879Z" fill="#FEC031"></path>
                                        <path d="M75.1271 4.42879C75.0494 4.20084 74.8363 4.03894 74.5841 4.01738L71.159 3.72235L69.8046 0.715105C69.7047 0.494713 69.4773 0.352051 69.2246 0.352051C68.9719 0.352051 68.7444 0.494713 68.6446 0.715621L67.2902 3.72235L63.8645 4.01738C63.6128 4.03945 63.4002 4.20084 63.3221 4.42879C63.2439 4.65674 63.3161 4.90676 63.5065 5.06437L66.0955 7.21831L65.3321 10.4085C65.2762 10.6431 65.3722 10.8855 65.5773 11.0262C65.6876 11.1018 65.8166 11.1403 65.9467 11.1403C66.0589 11.1403 66.1702 11.1116 66.2701 11.0549L69.2246 9.3798L72.178 11.0549C72.3941 11.1782 72.6666 11.167 72.8713 11.0262C73.0765 10.8851 73.1724 10.6425 73.1166 10.4085L72.3531 7.21831L74.9421 5.0648C75.1325 4.90676 75.2052 4.65717 75.1271 4.42879Z" fill="#FEC031"></path>
                                    </svg>
                                </div>
                            </div>

                            <div className='mx-2'>
                                <img src={require('./Assets/Sailor.png')} className='review_img' />
                            </div>
                        </div>
                    </div>

                    <div className='results_card'>
                        <div class="w-embed">
                            <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 17.3245C0 21.1391 2.9404 24 6.6755 24C10.4106 24 13.351 21.1391 13.351 17.3245C13.351 14.702 11.9205 12.4768 9.77484 11.4437L14.2252 0H7.94702L2.06623 11.4437C0.715232 14.0662 0 15.7351 0 17.3245ZM17.0066 17.3245C17.0066 21.1391 19.947 24 23.6821 24C27.4967 24 30.3576 21.1391 30.3576 17.3245C30.3576 14.702 28.9272 12.4768 26.7815 11.4437L31.2318 0H24.9536L19.0729 11.4437C17.7219 14.0662 17.0066 15.7351 17.0066 17.3245Z" fill="#97532e"></path>
                            </svg>
                        </div>
                        <p>
                            So the course is just as great as dhruvee rather himself, I like how the way he explain the things by showing his own life experiences and yeah that will help me a lot for sure, mainly the day 3 and 4 part will help me beat the procrastination
                        </p>

                        <div className='d-flex align-items-center'>
                            <div>
                                <p className='text-weight-bold text-size-medium'>
                                    Hardeep S.
                                </p>

                                <div class="w-embed">
                                    <svg width="76" height="12" viewBox="0 0 76 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.8361 4.42891C11.7584 4.20096 11.5453 4.03906 11.2931 4.0175L7.86797 3.72247L6.51357 0.715227C6.41371 0.494835 6.18627 0.352173 5.93357 0.352173C5.68087 0.352173 5.45343 0.494835 5.35357 0.715743L3.99917 3.72247L0.573482 4.0175C0.321779 4.03958 0.10919 4.20096 0.0310539 4.42891C-0.0470826 4.65686 0.0250782 4.90689 0.215485 5.06449L2.80449 7.21843L2.04105 10.4086C1.98519 10.6432 2.08116 10.8856 2.28633 11.0263C2.39661 11.1019 2.52563 11.1404 2.65573 11.1404C2.76791 11.1404 2.87919 11.1117 2.97905 11.055L5.93357 9.37992L8.887 11.055C9.10312 11.1784 9.37556 11.1671 9.58027 11.0263C9.78553 10.8852 9.88141 10.6427 9.82554 10.4086L9.06211 7.21843L11.6511 5.06492C11.8415 4.90689 11.9142 4.65729 11.8361 4.42891Z" fill="#FEC031"></path>
                                        <path d="M27.6584 4.42879C27.5807 4.20084 27.3675 4.03894 27.1154 4.01738L23.6902 3.72235L22.3358 0.715105C22.236 0.494713 22.0085 0.352051 21.7558 0.352051C21.5031 0.352051 21.2757 0.494713 21.1758 0.715621L19.8214 3.72235L16.3957 4.01738C16.144 4.03945 15.9315 4.20084 15.8533 4.42879C15.7752 4.65674 15.8473 4.90676 16.0378 5.06437L18.6268 7.21831L17.8633 10.4085C17.8075 10.6431 17.9034 10.8855 18.1086 11.0262C18.2189 11.1018 18.3479 11.1403 18.478 11.1403C18.5902 11.1403 18.7015 11.1116 18.8013 11.0549L21.7558 9.3798L24.7093 11.0549C24.9254 11.1782 25.1978 11.167 25.4025 11.0262C25.6078 10.8851 25.7037 10.6425 25.6478 10.4085L24.8844 7.21831L27.4734 5.0648C27.6638 4.90676 27.7365 4.65717 27.6584 4.42879Z" fill="#FEC031"></path>
                                        <path d="M43.4816 4.42879C43.4039 4.20084 43.1908 4.03894 42.9386 4.01738L39.5135 3.72235L38.1591 0.715105C38.0592 0.494713 37.8318 0.352051 37.5791 0.352051C37.3264 0.352051 37.0989 0.494713 36.9991 0.715621L35.6447 3.72235L32.219 4.01738C31.9673 4.03945 31.7547 4.20084 31.6766 4.42879C31.5984 4.65674 31.6706 4.90676 31.861 5.06437L34.45 7.21831L33.6866 10.4085C33.6307 10.6431 33.7267 10.8855 33.9318 11.0262C34.0421 11.1018 34.1711 11.1403 34.3012 11.1403C34.4134 11.1403 34.5247 11.1116 34.6246 11.0549L37.5791 9.3798L40.5325 11.0549C40.7486 11.1782 41.0211 11.167 41.2258 11.0262C41.431 10.8851 41.5269 10.6425 41.4711 10.4085L40.7076 7.21831L43.2966 5.0648C43.487 4.90676 43.5597 4.65717 43.4816 4.42879Z" fill="#FEC031"></path>
                                        <path d="M59.3039 4.42879C59.2262 4.20084 59.013 4.03894 58.7609 4.01738L55.3357 3.72235L53.9813 0.715105C53.8815 0.494713 53.654 0.352051 53.4013 0.352051C53.1486 0.352051 52.9212 0.494713 52.8213 0.715621L51.4669 3.72235L48.0413 4.01738C47.7896 4.03945 47.577 4.20084 47.4988 4.42879C47.4207 4.65674 47.4929 4.90676 47.6833 5.06437L50.2723 7.21831L49.5088 10.4085C49.453 10.6431 49.5489 10.8855 49.7541 11.0262C49.8644 11.1018 49.9934 11.1403 50.1235 11.1403C50.2357 11.1403 50.347 11.1116 50.4468 11.0549L53.4013 9.3798L56.3548 11.0549C56.5709 11.1782 56.8433 11.167 57.048 11.0262C57.2533 10.8851 57.3492 10.6425 57.2933 10.4085L56.5299 7.21831L59.1189 5.0648C59.3093 4.90676 59.382 4.65717 59.3039 4.42879Z" fill="#FEC031"></path>
                                        <path d="M75.1271 4.42879C75.0494 4.20084 74.8363 4.03894 74.5841 4.01738L71.159 3.72235L69.8046 0.715105C69.7047 0.494713 69.4773 0.352051 69.2246 0.352051C68.9719 0.352051 68.7444 0.494713 68.6446 0.715621L67.2902 3.72235L63.8645 4.01738C63.6128 4.03945 63.4002 4.20084 63.3221 4.42879C63.2439 4.65674 63.3161 4.90676 63.5065 5.06437L66.0955 7.21831L65.3321 10.4085C65.2762 10.6431 65.3722 10.8855 65.5773 11.0262C65.6876 11.1018 65.8166 11.1403 65.9467 11.1403C66.0589 11.1403 66.1702 11.1116 66.2701 11.0549L69.2246 9.3798L72.178 11.0549C72.3941 11.1782 72.6666 11.167 72.8713 11.0262C73.0765 10.8851 73.1724 10.6425 73.1166 10.4085L72.3531 7.21831L74.9421 5.0648C75.1325 4.90676 75.2052 4.65717 75.1271 4.42879Z" fill="#FEC031"></path>
                                    </svg>
                                </div>
                            </div>

                            <div className='mx-2'>
                                <img src={require('./Assets/Sailor.png')} className='review_img' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mx-2 d-flex flex-column'>
                    <div className='results_card'>
                        <div class="w-embed">
                            <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 17.3245C0 21.1391 2.9404 24 6.6755 24C10.4106 24 13.351 21.1391 13.351 17.3245C13.351 14.702 11.9205 12.4768 9.77484 11.4437L14.2252 0H7.94702L2.06623 11.4437C0.715232 14.0662 0 15.7351 0 17.3245ZM17.0066 17.3245C17.0066 21.1391 19.947 24 23.6821 24C27.4967 24 30.3576 21.1391 30.3576 17.3245C30.3576 14.702 28.9272 12.4768 26.7815 11.4437L31.2318 0H24.9536L19.0729 11.4437C17.7219 14.0662 17.0066 15.7351 17.0066 17.3245Z" fill="#97532e"></path>
                            </svg>
                        </div>
                        <p>
                            So the course is just as great as dhruvee rather himself, I like how the way he explain the things by showing his own life experiences and yeah that will help me a lot for sure, mainly the day 3 and 4 part will help me beat the procrastination
                        </p>

                        <div className='d-flex align-items-center'>
                            <div>
                                <p className='text-weight-bold text-size-medium'>
                                    Hardeep S.
                                </p>

                                <div class="w-embed">
                                    <svg width="76" height="12" viewBox="0 0 76 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.8361 4.42891C11.7584 4.20096 11.5453 4.03906 11.2931 4.0175L7.86797 3.72247L6.51357 0.715227C6.41371 0.494835 6.18627 0.352173 5.93357 0.352173C5.68087 0.352173 5.45343 0.494835 5.35357 0.715743L3.99917 3.72247L0.573482 4.0175C0.321779 4.03958 0.10919 4.20096 0.0310539 4.42891C-0.0470826 4.65686 0.0250782 4.90689 0.215485 5.06449L2.80449 7.21843L2.04105 10.4086C1.98519 10.6432 2.08116 10.8856 2.28633 11.0263C2.39661 11.1019 2.52563 11.1404 2.65573 11.1404C2.76791 11.1404 2.87919 11.1117 2.97905 11.055L5.93357 9.37992L8.887 11.055C9.10312 11.1784 9.37556 11.1671 9.58027 11.0263C9.78553 10.8852 9.88141 10.6427 9.82554 10.4086L9.06211 7.21843L11.6511 5.06492C11.8415 4.90689 11.9142 4.65729 11.8361 4.42891Z" fill="#FEC031"></path>
                                        <path d="M27.6584 4.42879C27.5807 4.20084 27.3675 4.03894 27.1154 4.01738L23.6902 3.72235L22.3358 0.715105C22.236 0.494713 22.0085 0.352051 21.7558 0.352051C21.5031 0.352051 21.2757 0.494713 21.1758 0.715621L19.8214 3.72235L16.3957 4.01738C16.144 4.03945 15.9315 4.20084 15.8533 4.42879C15.7752 4.65674 15.8473 4.90676 16.0378 5.06437L18.6268 7.21831L17.8633 10.4085C17.8075 10.6431 17.9034 10.8855 18.1086 11.0262C18.2189 11.1018 18.3479 11.1403 18.478 11.1403C18.5902 11.1403 18.7015 11.1116 18.8013 11.0549L21.7558 9.3798L24.7093 11.0549C24.9254 11.1782 25.1978 11.167 25.4025 11.0262C25.6078 10.8851 25.7037 10.6425 25.6478 10.4085L24.8844 7.21831L27.4734 5.0648C27.6638 4.90676 27.7365 4.65717 27.6584 4.42879Z" fill="#FEC031"></path>
                                        <path d="M43.4816 4.42879C43.4039 4.20084 43.1908 4.03894 42.9386 4.01738L39.5135 3.72235L38.1591 0.715105C38.0592 0.494713 37.8318 0.352051 37.5791 0.352051C37.3264 0.352051 37.0989 0.494713 36.9991 0.715621L35.6447 3.72235L32.219 4.01738C31.9673 4.03945 31.7547 4.20084 31.6766 4.42879C31.5984 4.65674 31.6706 4.90676 31.861 5.06437L34.45 7.21831L33.6866 10.4085C33.6307 10.6431 33.7267 10.8855 33.9318 11.0262C34.0421 11.1018 34.1711 11.1403 34.3012 11.1403C34.4134 11.1403 34.5247 11.1116 34.6246 11.0549L37.5791 9.3798L40.5325 11.0549C40.7486 11.1782 41.0211 11.167 41.2258 11.0262C41.431 10.8851 41.5269 10.6425 41.4711 10.4085L40.7076 7.21831L43.2966 5.0648C43.487 4.90676 43.5597 4.65717 43.4816 4.42879Z" fill="#FEC031"></path>
                                        <path d="M59.3039 4.42879C59.2262 4.20084 59.013 4.03894 58.7609 4.01738L55.3357 3.72235L53.9813 0.715105C53.8815 0.494713 53.654 0.352051 53.4013 0.352051C53.1486 0.352051 52.9212 0.494713 52.8213 0.715621L51.4669 3.72235L48.0413 4.01738C47.7896 4.03945 47.577 4.20084 47.4988 4.42879C47.4207 4.65674 47.4929 4.90676 47.6833 5.06437L50.2723 7.21831L49.5088 10.4085C49.453 10.6431 49.5489 10.8855 49.7541 11.0262C49.8644 11.1018 49.9934 11.1403 50.1235 11.1403C50.2357 11.1403 50.347 11.1116 50.4468 11.0549L53.4013 9.3798L56.3548 11.0549C56.5709 11.1782 56.8433 11.167 57.048 11.0262C57.2533 10.8851 57.3492 10.6425 57.2933 10.4085L56.5299 7.21831L59.1189 5.0648C59.3093 4.90676 59.382 4.65717 59.3039 4.42879Z" fill="#FEC031"></path>
                                        <path d="M75.1271 4.42879C75.0494 4.20084 74.8363 4.03894 74.5841 4.01738L71.159 3.72235L69.8046 0.715105C69.7047 0.494713 69.4773 0.352051 69.2246 0.352051C68.9719 0.352051 68.7444 0.494713 68.6446 0.715621L67.2902 3.72235L63.8645 4.01738C63.6128 4.03945 63.4002 4.20084 63.3221 4.42879C63.2439 4.65674 63.3161 4.90676 63.5065 5.06437L66.0955 7.21831L65.3321 10.4085C65.2762 10.6431 65.3722 10.8855 65.5773 11.0262C65.6876 11.1018 65.8166 11.1403 65.9467 11.1403C66.0589 11.1403 66.1702 11.1116 66.2701 11.0549L69.2246 9.3798L72.178 11.0549C72.3941 11.1782 72.6666 11.167 72.8713 11.0262C73.0765 10.8851 73.1724 10.6425 73.1166 10.4085L72.3531 7.21831L74.9421 5.0648C75.1325 4.90676 75.2052 4.65717 75.1271 4.42879Z" fill="#FEC031"></path>
                                    </svg>
                                </div>
                            </div>

                            <div className='mx-2'>
                                <img src={require('./Assets/Sailor.png')} className='review_img' />
                            </div>
                        </div>
                    </div>

                    <div className='results_card'>
                        <div class="w-embed">
                            <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 17.3245C0 21.1391 2.9404 24 6.6755 24C10.4106 24 13.351 21.1391 13.351 17.3245C13.351 14.702 11.9205 12.4768 9.77484 11.4437L14.2252 0H7.94702L2.06623 11.4437C0.715232 14.0662 0 15.7351 0 17.3245ZM17.0066 17.3245C17.0066 21.1391 19.947 24 23.6821 24C27.4967 24 30.3576 21.1391 30.3576 17.3245C30.3576 14.702 28.9272 12.4768 26.7815 11.4437L31.2318 0H24.9536L19.0729 11.4437C17.7219 14.0662 17.0066 15.7351 17.0066 17.3245Z" fill="#97532e"></path>
                            </svg>
                        </div>
                        <p>
                            So the course is just as great as dhruvee rather himself, I like how the way he explain the things by showing his own life experiences and yeah that will help me a lot for sure, mainly the day 3 and 4 part will help me beat the procrastination
                        </p>

                        <div className='d-flex align-items-center'>
                            <div>
                                <p className='text-weight-bold text-size-medium'>
                                    Hardeep S.
                                </p>

                                <div class="w-embed">
                                    <svg width="76" height="12" viewBox="0 0 76 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.8361 4.42891C11.7584 4.20096 11.5453 4.03906 11.2931 4.0175L7.86797 3.72247L6.51357 0.715227C6.41371 0.494835 6.18627 0.352173 5.93357 0.352173C5.68087 0.352173 5.45343 0.494835 5.35357 0.715743L3.99917 3.72247L0.573482 4.0175C0.321779 4.03958 0.10919 4.20096 0.0310539 4.42891C-0.0470826 4.65686 0.0250782 4.90689 0.215485 5.06449L2.80449 7.21843L2.04105 10.4086C1.98519 10.6432 2.08116 10.8856 2.28633 11.0263C2.39661 11.1019 2.52563 11.1404 2.65573 11.1404C2.76791 11.1404 2.87919 11.1117 2.97905 11.055L5.93357 9.37992L8.887 11.055C9.10312 11.1784 9.37556 11.1671 9.58027 11.0263C9.78553 10.8852 9.88141 10.6427 9.82554 10.4086L9.06211 7.21843L11.6511 5.06492C11.8415 4.90689 11.9142 4.65729 11.8361 4.42891Z" fill="#FEC031"></path>
                                        <path d="M27.6584 4.42879C27.5807 4.20084 27.3675 4.03894 27.1154 4.01738L23.6902 3.72235L22.3358 0.715105C22.236 0.494713 22.0085 0.352051 21.7558 0.352051C21.5031 0.352051 21.2757 0.494713 21.1758 0.715621L19.8214 3.72235L16.3957 4.01738C16.144 4.03945 15.9315 4.20084 15.8533 4.42879C15.7752 4.65674 15.8473 4.90676 16.0378 5.06437L18.6268 7.21831L17.8633 10.4085C17.8075 10.6431 17.9034 10.8855 18.1086 11.0262C18.2189 11.1018 18.3479 11.1403 18.478 11.1403C18.5902 11.1403 18.7015 11.1116 18.8013 11.0549L21.7558 9.3798L24.7093 11.0549C24.9254 11.1782 25.1978 11.167 25.4025 11.0262C25.6078 10.8851 25.7037 10.6425 25.6478 10.4085L24.8844 7.21831L27.4734 5.0648C27.6638 4.90676 27.7365 4.65717 27.6584 4.42879Z" fill="#FEC031"></path>
                                        <path d="M43.4816 4.42879C43.4039 4.20084 43.1908 4.03894 42.9386 4.01738L39.5135 3.72235L38.1591 0.715105C38.0592 0.494713 37.8318 0.352051 37.5791 0.352051C37.3264 0.352051 37.0989 0.494713 36.9991 0.715621L35.6447 3.72235L32.219 4.01738C31.9673 4.03945 31.7547 4.20084 31.6766 4.42879C31.5984 4.65674 31.6706 4.90676 31.861 5.06437L34.45 7.21831L33.6866 10.4085C33.6307 10.6431 33.7267 10.8855 33.9318 11.0262C34.0421 11.1018 34.1711 11.1403 34.3012 11.1403C34.4134 11.1403 34.5247 11.1116 34.6246 11.0549L37.5791 9.3798L40.5325 11.0549C40.7486 11.1782 41.0211 11.167 41.2258 11.0262C41.431 10.8851 41.5269 10.6425 41.4711 10.4085L40.7076 7.21831L43.2966 5.0648C43.487 4.90676 43.5597 4.65717 43.4816 4.42879Z" fill="#FEC031"></path>
                                        <path d="M59.3039 4.42879C59.2262 4.20084 59.013 4.03894 58.7609 4.01738L55.3357 3.72235L53.9813 0.715105C53.8815 0.494713 53.654 0.352051 53.4013 0.352051C53.1486 0.352051 52.9212 0.494713 52.8213 0.715621L51.4669 3.72235L48.0413 4.01738C47.7896 4.03945 47.577 4.20084 47.4988 4.42879C47.4207 4.65674 47.4929 4.90676 47.6833 5.06437L50.2723 7.21831L49.5088 10.4085C49.453 10.6431 49.5489 10.8855 49.7541 11.0262C49.8644 11.1018 49.9934 11.1403 50.1235 11.1403C50.2357 11.1403 50.347 11.1116 50.4468 11.0549L53.4013 9.3798L56.3548 11.0549C56.5709 11.1782 56.8433 11.167 57.048 11.0262C57.2533 10.8851 57.3492 10.6425 57.2933 10.4085L56.5299 7.21831L59.1189 5.0648C59.3093 4.90676 59.382 4.65717 59.3039 4.42879Z" fill="#FEC031"></path>
                                        <path d="M75.1271 4.42879C75.0494 4.20084 74.8363 4.03894 74.5841 4.01738L71.159 3.72235L69.8046 0.715105C69.7047 0.494713 69.4773 0.352051 69.2246 0.352051C68.9719 0.352051 68.7444 0.494713 68.6446 0.715621L67.2902 3.72235L63.8645 4.01738C63.6128 4.03945 63.4002 4.20084 63.3221 4.42879C63.2439 4.65674 63.3161 4.90676 63.5065 5.06437L66.0955 7.21831L65.3321 10.4085C65.2762 10.6431 65.3722 10.8855 65.5773 11.0262C65.6876 11.1018 65.8166 11.1403 65.9467 11.1403C66.0589 11.1403 66.1702 11.1116 66.2701 11.0549L69.2246 9.3798L72.178 11.0549C72.3941 11.1782 72.6666 11.167 72.8713 11.0262C73.0765 10.8851 73.1724 10.6425 73.1166 10.4085L72.3531 7.21831L74.9421 5.0648C75.1325 4.90676 75.2052 4.65717 75.1271 4.42879Z" fill="#FEC031"></path>
                                    </svg>
                                </div>
                            </div>

                            <div className='mx-2'>
                                <img src={require('./Assets/Sailor.png')} className='review_img' />
                            </div>
                        </div>
                    </div>

                    <div className='results_card'>
                        <div class="w-embed">
                            <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 17.3245C0 21.1391 2.9404 24 6.6755 24C10.4106 24 13.351 21.1391 13.351 17.3245C13.351 14.702 11.9205 12.4768 9.77484 11.4437L14.2252 0H7.94702L2.06623 11.4437C0.715232 14.0662 0 15.7351 0 17.3245ZM17.0066 17.3245C17.0066 21.1391 19.947 24 23.6821 24C27.4967 24 30.3576 21.1391 30.3576 17.3245C30.3576 14.702 28.9272 12.4768 26.7815 11.4437L31.2318 0H24.9536L19.0729 11.4437C17.7219 14.0662 17.0066 15.7351 17.0066 17.3245Z" fill="#97532e"></path>
                            </svg>
                        </div>
                        <p>
                            So the course is just as great as dhruvee rather himself, I like how the way he explain the things by showing his own life experiences and yeah that will help me a lot for sure, mainly the day 3 and 4 part will help me beat the procrastination
                        </p>

                        <div className='d-flex align-items-center'>
                            <div>
                                <p className='text-weight-bold text-size-medium'>
                                    Hardeep S.
                                </p>

                                <div class="w-embed">
                                    <svg width="76" height="12" viewBox="0 0 76 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.8361 4.42891C11.7584 4.20096 11.5453 4.03906 11.2931 4.0175L7.86797 3.72247L6.51357 0.715227C6.41371 0.494835 6.18627 0.352173 5.93357 0.352173C5.68087 0.352173 5.45343 0.494835 5.35357 0.715743L3.99917 3.72247L0.573482 4.0175C0.321779 4.03958 0.10919 4.20096 0.0310539 4.42891C-0.0470826 4.65686 0.0250782 4.90689 0.215485 5.06449L2.80449 7.21843L2.04105 10.4086C1.98519 10.6432 2.08116 10.8856 2.28633 11.0263C2.39661 11.1019 2.52563 11.1404 2.65573 11.1404C2.76791 11.1404 2.87919 11.1117 2.97905 11.055L5.93357 9.37992L8.887 11.055C9.10312 11.1784 9.37556 11.1671 9.58027 11.0263C9.78553 10.8852 9.88141 10.6427 9.82554 10.4086L9.06211 7.21843L11.6511 5.06492C11.8415 4.90689 11.9142 4.65729 11.8361 4.42891Z" fill="#FEC031"></path>
                                        <path d="M27.6584 4.42879C27.5807 4.20084 27.3675 4.03894 27.1154 4.01738L23.6902 3.72235L22.3358 0.715105C22.236 0.494713 22.0085 0.352051 21.7558 0.352051C21.5031 0.352051 21.2757 0.494713 21.1758 0.715621L19.8214 3.72235L16.3957 4.01738C16.144 4.03945 15.9315 4.20084 15.8533 4.42879C15.7752 4.65674 15.8473 4.90676 16.0378 5.06437L18.6268 7.21831L17.8633 10.4085C17.8075 10.6431 17.9034 10.8855 18.1086 11.0262C18.2189 11.1018 18.3479 11.1403 18.478 11.1403C18.5902 11.1403 18.7015 11.1116 18.8013 11.0549L21.7558 9.3798L24.7093 11.0549C24.9254 11.1782 25.1978 11.167 25.4025 11.0262C25.6078 10.8851 25.7037 10.6425 25.6478 10.4085L24.8844 7.21831L27.4734 5.0648C27.6638 4.90676 27.7365 4.65717 27.6584 4.42879Z" fill="#FEC031"></path>
                                        <path d="M43.4816 4.42879C43.4039 4.20084 43.1908 4.03894 42.9386 4.01738L39.5135 3.72235L38.1591 0.715105C38.0592 0.494713 37.8318 0.352051 37.5791 0.352051C37.3264 0.352051 37.0989 0.494713 36.9991 0.715621L35.6447 3.72235L32.219 4.01738C31.9673 4.03945 31.7547 4.20084 31.6766 4.42879C31.5984 4.65674 31.6706 4.90676 31.861 5.06437L34.45 7.21831L33.6866 10.4085C33.6307 10.6431 33.7267 10.8855 33.9318 11.0262C34.0421 11.1018 34.1711 11.1403 34.3012 11.1403C34.4134 11.1403 34.5247 11.1116 34.6246 11.0549L37.5791 9.3798L40.5325 11.0549C40.7486 11.1782 41.0211 11.167 41.2258 11.0262C41.431 10.8851 41.5269 10.6425 41.4711 10.4085L40.7076 7.21831L43.2966 5.0648C43.487 4.90676 43.5597 4.65717 43.4816 4.42879Z" fill="#FEC031"></path>
                                        <path d="M59.3039 4.42879C59.2262 4.20084 59.013 4.03894 58.7609 4.01738L55.3357 3.72235L53.9813 0.715105C53.8815 0.494713 53.654 0.352051 53.4013 0.352051C53.1486 0.352051 52.9212 0.494713 52.8213 0.715621L51.4669 3.72235L48.0413 4.01738C47.7896 4.03945 47.577 4.20084 47.4988 4.42879C47.4207 4.65674 47.4929 4.90676 47.6833 5.06437L50.2723 7.21831L49.5088 10.4085C49.453 10.6431 49.5489 10.8855 49.7541 11.0262C49.8644 11.1018 49.9934 11.1403 50.1235 11.1403C50.2357 11.1403 50.347 11.1116 50.4468 11.0549L53.4013 9.3798L56.3548 11.0549C56.5709 11.1782 56.8433 11.167 57.048 11.0262C57.2533 10.8851 57.3492 10.6425 57.2933 10.4085L56.5299 7.21831L59.1189 5.0648C59.3093 4.90676 59.382 4.65717 59.3039 4.42879Z" fill="#FEC031"></path>
                                        <path d="M75.1271 4.42879C75.0494 4.20084 74.8363 4.03894 74.5841 4.01738L71.159 3.72235L69.8046 0.715105C69.7047 0.494713 69.4773 0.352051 69.2246 0.352051C68.9719 0.352051 68.7444 0.494713 68.6446 0.715621L67.2902 3.72235L63.8645 4.01738C63.6128 4.03945 63.4002 4.20084 63.3221 4.42879C63.2439 4.65674 63.3161 4.90676 63.5065 5.06437L66.0955 7.21831L65.3321 10.4085C65.2762 10.6431 65.3722 10.8855 65.5773 11.0262C65.6876 11.1018 65.8166 11.1403 65.9467 11.1403C66.0589 11.1403 66.1702 11.1116 66.2701 11.0549L69.2246 9.3798L72.178 11.0549C72.3941 11.1782 72.6666 11.167 72.8713 11.0262C73.0765 10.8851 73.1724 10.6425 73.1166 10.4085L72.3531 7.21831L74.9421 5.0648C75.1325 4.90676 75.2052 4.65717 75.1271 4.42879Z" fill="#FEC031"></path>
                                    </svg>
                                </div>
                            </div>

                            <div className='mx-2'>
                                <img src={require('./Assets/Sailor.png')} className='review_img' />
                            </div>
                        </div>
                    </div>

                    <div className='results_card'>
                        <div class="w-embed">
                            <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 17.3245C0 21.1391 2.9404 24 6.6755 24C10.4106 24 13.351 21.1391 13.351 17.3245C13.351 14.702 11.9205 12.4768 9.77484 11.4437L14.2252 0H7.94702L2.06623 11.4437C0.715232 14.0662 0 15.7351 0 17.3245ZM17.0066 17.3245C17.0066 21.1391 19.947 24 23.6821 24C27.4967 24 30.3576 21.1391 30.3576 17.3245C30.3576 14.702 28.9272 12.4768 26.7815 11.4437L31.2318 0H24.9536L19.0729 11.4437C17.7219 14.0662 17.0066 15.7351 17.0066 17.3245Z" fill="#97532e"></path>
                            </svg>
                        </div>
                        <p>
                            So the course is just as great as dhruvee rather himself, I like how the way he explain the things by showing his own life experiences and yeah that will help me a lot for sure, mainly the day 3 and 4 part will help me beat the procrastination
                        </p>

                        <div className='d-flex align-items-center'>
                            <div>
                                <p className='text-weight-bold text-size-medium'>
                                    Hardeep S.
                                </p>

                                <div class="w-embed">
                                    <svg width="76" height="12" viewBox="0 0 76 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.8361 4.42891C11.7584 4.20096 11.5453 4.03906 11.2931 4.0175L7.86797 3.72247L6.51357 0.715227C6.41371 0.494835 6.18627 0.352173 5.93357 0.352173C5.68087 0.352173 5.45343 0.494835 5.35357 0.715743L3.99917 3.72247L0.573482 4.0175C0.321779 4.03958 0.10919 4.20096 0.0310539 4.42891C-0.0470826 4.65686 0.0250782 4.90689 0.215485 5.06449L2.80449 7.21843L2.04105 10.4086C1.98519 10.6432 2.08116 10.8856 2.28633 11.0263C2.39661 11.1019 2.52563 11.1404 2.65573 11.1404C2.76791 11.1404 2.87919 11.1117 2.97905 11.055L5.93357 9.37992L8.887 11.055C9.10312 11.1784 9.37556 11.1671 9.58027 11.0263C9.78553 10.8852 9.88141 10.6427 9.82554 10.4086L9.06211 7.21843L11.6511 5.06492C11.8415 4.90689 11.9142 4.65729 11.8361 4.42891Z" fill="#FEC031"></path>
                                        <path d="M27.6584 4.42879C27.5807 4.20084 27.3675 4.03894 27.1154 4.01738L23.6902 3.72235L22.3358 0.715105C22.236 0.494713 22.0085 0.352051 21.7558 0.352051C21.5031 0.352051 21.2757 0.494713 21.1758 0.715621L19.8214 3.72235L16.3957 4.01738C16.144 4.03945 15.9315 4.20084 15.8533 4.42879C15.7752 4.65674 15.8473 4.90676 16.0378 5.06437L18.6268 7.21831L17.8633 10.4085C17.8075 10.6431 17.9034 10.8855 18.1086 11.0262C18.2189 11.1018 18.3479 11.1403 18.478 11.1403C18.5902 11.1403 18.7015 11.1116 18.8013 11.0549L21.7558 9.3798L24.7093 11.0549C24.9254 11.1782 25.1978 11.167 25.4025 11.0262C25.6078 10.8851 25.7037 10.6425 25.6478 10.4085L24.8844 7.21831L27.4734 5.0648C27.6638 4.90676 27.7365 4.65717 27.6584 4.42879Z" fill="#FEC031"></path>
                                        <path d="M43.4816 4.42879C43.4039 4.20084 43.1908 4.03894 42.9386 4.01738L39.5135 3.72235L38.1591 0.715105C38.0592 0.494713 37.8318 0.352051 37.5791 0.352051C37.3264 0.352051 37.0989 0.494713 36.9991 0.715621L35.6447 3.72235L32.219 4.01738C31.9673 4.03945 31.7547 4.20084 31.6766 4.42879C31.5984 4.65674 31.6706 4.90676 31.861 5.06437L34.45 7.21831L33.6866 10.4085C33.6307 10.6431 33.7267 10.8855 33.9318 11.0262C34.0421 11.1018 34.1711 11.1403 34.3012 11.1403C34.4134 11.1403 34.5247 11.1116 34.6246 11.0549L37.5791 9.3798L40.5325 11.0549C40.7486 11.1782 41.0211 11.167 41.2258 11.0262C41.431 10.8851 41.5269 10.6425 41.4711 10.4085L40.7076 7.21831L43.2966 5.0648C43.487 4.90676 43.5597 4.65717 43.4816 4.42879Z" fill="#FEC031"></path>
                                        <path d="M59.3039 4.42879C59.2262 4.20084 59.013 4.03894 58.7609 4.01738L55.3357 3.72235L53.9813 0.715105C53.8815 0.494713 53.654 0.352051 53.4013 0.352051C53.1486 0.352051 52.9212 0.494713 52.8213 0.715621L51.4669 3.72235L48.0413 4.01738C47.7896 4.03945 47.577 4.20084 47.4988 4.42879C47.4207 4.65674 47.4929 4.90676 47.6833 5.06437L50.2723 7.21831L49.5088 10.4085C49.453 10.6431 49.5489 10.8855 49.7541 11.0262C49.8644 11.1018 49.9934 11.1403 50.1235 11.1403C50.2357 11.1403 50.347 11.1116 50.4468 11.0549L53.4013 9.3798L56.3548 11.0549C56.5709 11.1782 56.8433 11.167 57.048 11.0262C57.2533 10.8851 57.3492 10.6425 57.2933 10.4085L56.5299 7.21831L59.1189 5.0648C59.3093 4.90676 59.382 4.65717 59.3039 4.42879Z" fill="#FEC031"></path>
                                        <path d="M75.1271 4.42879C75.0494 4.20084 74.8363 4.03894 74.5841 4.01738L71.159 3.72235L69.8046 0.715105C69.7047 0.494713 69.4773 0.352051 69.2246 0.352051C68.9719 0.352051 68.7444 0.494713 68.6446 0.715621L67.2902 3.72235L63.8645 4.01738C63.6128 4.03945 63.4002 4.20084 63.3221 4.42879C63.2439 4.65674 63.3161 4.90676 63.5065 5.06437L66.0955 7.21831L65.3321 10.4085C65.2762 10.6431 65.3722 10.8855 65.5773 11.0262C65.6876 11.1018 65.8166 11.1403 65.9467 11.1403C66.0589 11.1403 66.1702 11.1116 66.2701 11.0549L69.2246 9.3798L72.178 11.0549C72.3941 11.1782 72.6666 11.167 72.8713 11.0262C73.0765 10.8851 73.1724 10.6425 73.1166 10.4085L72.3531 7.21831L74.9421 5.0648C75.1325 4.90676 75.2052 4.65717 75.1271 4.42879Z" fill="#FEC031"></path>
                                    </svg>
                                </div>
                            </div>

                            <div className='mx-2'>
                                <img src={require('./Assets/Sailor.png')} className='review_img' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mx-2 d-flex flex-column'>
                    <div className='results_card'>
                        <div class="w-embed">
                            <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 17.3245C0 21.1391 2.9404 24 6.6755 24C10.4106 24 13.351 21.1391 13.351 17.3245C13.351 14.702 11.9205 12.4768 9.77484 11.4437L14.2252 0H7.94702L2.06623 11.4437C0.715232 14.0662 0 15.7351 0 17.3245ZM17.0066 17.3245C17.0066 21.1391 19.947 24 23.6821 24C27.4967 24 30.3576 21.1391 30.3576 17.3245C30.3576 14.702 28.9272 12.4768 26.7815 11.4437L31.2318 0H24.9536L19.0729 11.4437C17.7219 14.0662 17.0066 15.7351 17.0066 17.3245Z" fill="#97532e"></path>
                            </svg>
                        </div>
                        <p>
                        Great insights by Dhruv! He has managed to put the core concepts of not only being productive but also to live a better life
                        </p>

                        <div className='d-flex align-items-center'>
                            <div>
                                <p className='text-weight-bold text-size-medium'>
                                    Hardeep S.
                                </p>

                                <div class="w-embed">
                                    <svg width="76" height="12" viewBox="0 0 76 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.8361 4.42891C11.7584 4.20096 11.5453 4.03906 11.2931 4.0175L7.86797 3.72247L6.51357 0.715227C6.41371 0.494835 6.18627 0.352173 5.93357 0.352173C5.68087 0.352173 5.45343 0.494835 5.35357 0.715743L3.99917 3.72247L0.573482 4.0175C0.321779 4.03958 0.10919 4.20096 0.0310539 4.42891C-0.0470826 4.65686 0.0250782 4.90689 0.215485 5.06449L2.80449 7.21843L2.04105 10.4086C1.98519 10.6432 2.08116 10.8856 2.28633 11.0263C2.39661 11.1019 2.52563 11.1404 2.65573 11.1404C2.76791 11.1404 2.87919 11.1117 2.97905 11.055L5.93357 9.37992L8.887 11.055C9.10312 11.1784 9.37556 11.1671 9.58027 11.0263C9.78553 10.8852 9.88141 10.6427 9.82554 10.4086L9.06211 7.21843L11.6511 5.06492C11.8415 4.90689 11.9142 4.65729 11.8361 4.42891Z" fill="#FEC031"></path>
                                        <path d="M27.6584 4.42879C27.5807 4.20084 27.3675 4.03894 27.1154 4.01738L23.6902 3.72235L22.3358 0.715105C22.236 0.494713 22.0085 0.352051 21.7558 0.352051C21.5031 0.352051 21.2757 0.494713 21.1758 0.715621L19.8214 3.72235L16.3957 4.01738C16.144 4.03945 15.9315 4.20084 15.8533 4.42879C15.7752 4.65674 15.8473 4.90676 16.0378 5.06437L18.6268 7.21831L17.8633 10.4085C17.8075 10.6431 17.9034 10.8855 18.1086 11.0262C18.2189 11.1018 18.3479 11.1403 18.478 11.1403C18.5902 11.1403 18.7015 11.1116 18.8013 11.0549L21.7558 9.3798L24.7093 11.0549C24.9254 11.1782 25.1978 11.167 25.4025 11.0262C25.6078 10.8851 25.7037 10.6425 25.6478 10.4085L24.8844 7.21831L27.4734 5.0648C27.6638 4.90676 27.7365 4.65717 27.6584 4.42879Z" fill="#FEC031"></path>
                                        <path d="M43.4816 4.42879C43.4039 4.20084 43.1908 4.03894 42.9386 4.01738L39.5135 3.72235L38.1591 0.715105C38.0592 0.494713 37.8318 0.352051 37.5791 0.352051C37.3264 0.352051 37.0989 0.494713 36.9991 0.715621L35.6447 3.72235L32.219 4.01738C31.9673 4.03945 31.7547 4.20084 31.6766 4.42879C31.5984 4.65674 31.6706 4.90676 31.861 5.06437L34.45 7.21831L33.6866 10.4085C33.6307 10.6431 33.7267 10.8855 33.9318 11.0262C34.0421 11.1018 34.1711 11.1403 34.3012 11.1403C34.4134 11.1403 34.5247 11.1116 34.6246 11.0549L37.5791 9.3798L40.5325 11.0549C40.7486 11.1782 41.0211 11.167 41.2258 11.0262C41.431 10.8851 41.5269 10.6425 41.4711 10.4085L40.7076 7.21831L43.2966 5.0648C43.487 4.90676 43.5597 4.65717 43.4816 4.42879Z" fill="#FEC031"></path>
                                        <path d="M59.3039 4.42879C59.2262 4.20084 59.013 4.03894 58.7609 4.01738L55.3357 3.72235L53.9813 0.715105C53.8815 0.494713 53.654 0.352051 53.4013 0.352051C53.1486 0.352051 52.9212 0.494713 52.8213 0.715621L51.4669 3.72235L48.0413 4.01738C47.7896 4.03945 47.577 4.20084 47.4988 4.42879C47.4207 4.65674 47.4929 4.90676 47.6833 5.06437L50.2723 7.21831L49.5088 10.4085C49.453 10.6431 49.5489 10.8855 49.7541 11.0262C49.8644 11.1018 49.9934 11.1403 50.1235 11.1403C50.2357 11.1403 50.347 11.1116 50.4468 11.0549L53.4013 9.3798L56.3548 11.0549C56.5709 11.1782 56.8433 11.167 57.048 11.0262C57.2533 10.8851 57.3492 10.6425 57.2933 10.4085L56.5299 7.21831L59.1189 5.0648C59.3093 4.90676 59.382 4.65717 59.3039 4.42879Z" fill="#FEC031"></path>
                                        <path d="M75.1271 4.42879C75.0494 4.20084 74.8363 4.03894 74.5841 4.01738L71.159 3.72235L69.8046 0.715105C69.7047 0.494713 69.4773 0.352051 69.2246 0.352051C68.9719 0.352051 68.7444 0.494713 68.6446 0.715621L67.2902 3.72235L63.8645 4.01738C63.6128 4.03945 63.4002 4.20084 63.3221 4.42879C63.2439 4.65674 63.3161 4.90676 63.5065 5.06437L66.0955 7.21831L65.3321 10.4085C65.2762 10.6431 65.3722 10.8855 65.5773 11.0262C65.6876 11.1018 65.8166 11.1403 65.9467 11.1403C66.0589 11.1403 66.1702 11.1116 66.2701 11.0549L69.2246 9.3798L72.178 11.0549C72.3941 11.1782 72.6666 11.167 72.8713 11.0262C73.0765 10.8851 73.1724 10.6425 73.1166 10.4085L72.3531 7.21831L74.9421 5.0648C75.1325 4.90676 75.2052 4.65717 75.1271 4.42879Z" fill="#FEC031"></path>
                                    </svg>
                                </div>
                            </div>

                            <div className='mx-2'>
                                <img src={require('./Assets/Sailor.png')} className='review_img' />
                            </div>
                        </div>
                    </div>

                    <div className='results_card'>
                        <div class="w-embed">
                            <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 17.3245C0 21.1391 2.9404 24 6.6755 24C10.4106 24 13.351 21.1391 13.351 17.3245C13.351 14.702 11.9205 12.4768 9.77484 11.4437L14.2252 0H7.94702L2.06623 11.4437C0.715232 14.0662 0 15.7351 0 17.3245ZM17.0066 17.3245C17.0066 21.1391 19.947 24 23.6821 24C27.4967 24 30.3576 21.1391 30.3576 17.3245C30.3576 14.702 28.9272 12.4768 26.7815 11.4437L31.2318 0H24.9536L19.0729 11.4437C17.7219 14.0662 17.0066 15.7351 17.0066 17.3245Z" fill="#97532e"></path>
                            </svg>
                        </div>
                        <p>
                            So the course is just as great as dhruvee rather himself, I like how the way he explain the things by showing his own life experiences and yeah that will help me a lot for sure, mainly the day 3 and 4 part will help me beat the procrastination
                        </p>

                        <div className='d-flex align-items-center'>
                            <div>
                                <p className='text-weight-bold text-size-medium'>
                                    Hardeep S.
                                </p>

                                <div class="w-embed">
                                    <svg width="76" height="12" viewBox="0 0 76 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.8361 4.42891C11.7584 4.20096 11.5453 4.03906 11.2931 4.0175L7.86797 3.72247L6.51357 0.715227C6.41371 0.494835 6.18627 0.352173 5.93357 0.352173C5.68087 0.352173 5.45343 0.494835 5.35357 0.715743L3.99917 3.72247L0.573482 4.0175C0.321779 4.03958 0.10919 4.20096 0.0310539 4.42891C-0.0470826 4.65686 0.0250782 4.90689 0.215485 5.06449L2.80449 7.21843L2.04105 10.4086C1.98519 10.6432 2.08116 10.8856 2.28633 11.0263C2.39661 11.1019 2.52563 11.1404 2.65573 11.1404C2.76791 11.1404 2.87919 11.1117 2.97905 11.055L5.93357 9.37992L8.887 11.055C9.10312 11.1784 9.37556 11.1671 9.58027 11.0263C9.78553 10.8852 9.88141 10.6427 9.82554 10.4086L9.06211 7.21843L11.6511 5.06492C11.8415 4.90689 11.9142 4.65729 11.8361 4.42891Z" fill="#FEC031"></path>
                                        <path d="M27.6584 4.42879C27.5807 4.20084 27.3675 4.03894 27.1154 4.01738L23.6902 3.72235L22.3358 0.715105C22.236 0.494713 22.0085 0.352051 21.7558 0.352051C21.5031 0.352051 21.2757 0.494713 21.1758 0.715621L19.8214 3.72235L16.3957 4.01738C16.144 4.03945 15.9315 4.20084 15.8533 4.42879C15.7752 4.65674 15.8473 4.90676 16.0378 5.06437L18.6268 7.21831L17.8633 10.4085C17.8075 10.6431 17.9034 10.8855 18.1086 11.0262C18.2189 11.1018 18.3479 11.1403 18.478 11.1403C18.5902 11.1403 18.7015 11.1116 18.8013 11.0549L21.7558 9.3798L24.7093 11.0549C24.9254 11.1782 25.1978 11.167 25.4025 11.0262C25.6078 10.8851 25.7037 10.6425 25.6478 10.4085L24.8844 7.21831L27.4734 5.0648C27.6638 4.90676 27.7365 4.65717 27.6584 4.42879Z" fill="#FEC031"></path>
                                        <path d="M43.4816 4.42879C43.4039 4.20084 43.1908 4.03894 42.9386 4.01738L39.5135 3.72235L38.1591 0.715105C38.0592 0.494713 37.8318 0.352051 37.5791 0.352051C37.3264 0.352051 37.0989 0.494713 36.9991 0.715621L35.6447 3.72235L32.219 4.01738C31.9673 4.03945 31.7547 4.20084 31.6766 4.42879C31.5984 4.65674 31.6706 4.90676 31.861 5.06437L34.45 7.21831L33.6866 10.4085C33.6307 10.6431 33.7267 10.8855 33.9318 11.0262C34.0421 11.1018 34.1711 11.1403 34.3012 11.1403C34.4134 11.1403 34.5247 11.1116 34.6246 11.0549L37.5791 9.3798L40.5325 11.0549C40.7486 11.1782 41.0211 11.167 41.2258 11.0262C41.431 10.8851 41.5269 10.6425 41.4711 10.4085L40.7076 7.21831L43.2966 5.0648C43.487 4.90676 43.5597 4.65717 43.4816 4.42879Z" fill="#FEC031"></path>
                                        <path d="M59.3039 4.42879C59.2262 4.20084 59.013 4.03894 58.7609 4.01738L55.3357 3.72235L53.9813 0.715105C53.8815 0.494713 53.654 0.352051 53.4013 0.352051C53.1486 0.352051 52.9212 0.494713 52.8213 0.715621L51.4669 3.72235L48.0413 4.01738C47.7896 4.03945 47.577 4.20084 47.4988 4.42879C47.4207 4.65674 47.4929 4.90676 47.6833 5.06437L50.2723 7.21831L49.5088 10.4085C49.453 10.6431 49.5489 10.8855 49.7541 11.0262C49.8644 11.1018 49.9934 11.1403 50.1235 11.1403C50.2357 11.1403 50.347 11.1116 50.4468 11.0549L53.4013 9.3798L56.3548 11.0549C56.5709 11.1782 56.8433 11.167 57.048 11.0262C57.2533 10.8851 57.3492 10.6425 57.2933 10.4085L56.5299 7.21831L59.1189 5.0648C59.3093 4.90676 59.382 4.65717 59.3039 4.42879Z" fill="#FEC031"></path>
                                        <path d="M75.1271 4.42879C75.0494 4.20084 74.8363 4.03894 74.5841 4.01738L71.159 3.72235L69.8046 0.715105C69.7047 0.494713 69.4773 0.352051 69.2246 0.352051C68.9719 0.352051 68.7444 0.494713 68.6446 0.715621L67.2902 3.72235L63.8645 4.01738C63.6128 4.03945 63.4002 4.20084 63.3221 4.42879C63.2439 4.65674 63.3161 4.90676 63.5065 5.06437L66.0955 7.21831L65.3321 10.4085C65.2762 10.6431 65.3722 10.8855 65.5773 11.0262C65.6876 11.1018 65.8166 11.1403 65.9467 11.1403C66.0589 11.1403 66.1702 11.1116 66.2701 11.0549L69.2246 9.3798L72.178 11.0549C72.3941 11.1782 72.6666 11.167 72.8713 11.0262C73.0765 10.8851 73.1724 10.6425 73.1166 10.4085L72.3531 7.21831L74.9421 5.0648C75.1325 4.90676 75.2052 4.65717 75.1271 4.42879Z" fill="#FEC031"></path>
                                    </svg>
                                </div>
                            </div>

                            <div className='mx-2'>
                                <img src={require('./Assets/Sailor.png')} className='review_img' />
                            </div>
                        </div>
                    </div>

                    <div className='results_card'>
                        <div class="w-embed">
                            <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 17.3245C0 21.1391 2.9404 24 6.6755 24C10.4106 24 13.351 21.1391 13.351 17.3245C13.351 14.702 11.9205 12.4768 9.77484 11.4437L14.2252 0H7.94702L2.06623 11.4437C0.715232 14.0662 0 15.7351 0 17.3245ZM17.0066 17.3245C17.0066 21.1391 19.947 24 23.6821 24C27.4967 24 30.3576 21.1391 30.3576 17.3245C30.3576 14.702 28.9272 12.4768 26.7815 11.4437L31.2318 0H24.9536L19.0729 11.4437C17.7219 14.0662 17.0066 15.7351 17.0066 17.3245Z" fill="#97532e"></path>
                            </svg>
                        </div>
                        <p>
                            So the course is just as great as dhruvee rather himself, I like how the way he explain the things by showing his own life experiences and yeah that will help me a lot for sure, mainly the day 3 and 4 part will help me beat the procrastination
                        </p>

                        <div className='d-flex align-items-center'>
                            <div>
                                <p className='text-weight-bold text-size-medium'>
                                    Hardeep S.
                                </p>

                                <div class="w-embed">
                                    <svg width="76" height="12" viewBox="0 0 76 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.8361 4.42891C11.7584 4.20096 11.5453 4.03906 11.2931 4.0175L7.86797 3.72247L6.51357 0.715227C6.41371 0.494835 6.18627 0.352173 5.93357 0.352173C5.68087 0.352173 5.45343 0.494835 5.35357 0.715743L3.99917 3.72247L0.573482 4.0175C0.321779 4.03958 0.10919 4.20096 0.0310539 4.42891C-0.0470826 4.65686 0.0250782 4.90689 0.215485 5.06449L2.80449 7.21843L2.04105 10.4086C1.98519 10.6432 2.08116 10.8856 2.28633 11.0263C2.39661 11.1019 2.52563 11.1404 2.65573 11.1404C2.76791 11.1404 2.87919 11.1117 2.97905 11.055L5.93357 9.37992L8.887 11.055C9.10312 11.1784 9.37556 11.1671 9.58027 11.0263C9.78553 10.8852 9.88141 10.6427 9.82554 10.4086L9.06211 7.21843L11.6511 5.06492C11.8415 4.90689 11.9142 4.65729 11.8361 4.42891Z" fill="#FEC031"></path>
                                        <path d="M27.6584 4.42879C27.5807 4.20084 27.3675 4.03894 27.1154 4.01738L23.6902 3.72235L22.3358 0.715105C22.236 0.494713 22.0085 0.352051 21.7558 0.352051C21.5031 0.352051 21.2757 0.494713 21.1758 0.715621L19.8214 3.72235L16.3957 4.01738C16.144 4.03945 15.9315 4.20084 15.8533 4.42879C15.7752 4.65674 15.8473 4.90676 16.0378 5.06437L18.6268 7.21831L17.8633 10.4085C17.8075 10.6431 17.9034 10.8855 18.1086 11.0262C18.2189 11.1018 18.3479 11.1403 18.478 11.1403C18.5902 11.1403 18.7015 11.1116 18.8013 11.0549L21.7558 9.3798L24.7093 11.0549C24.9254 11.1782 25.1978 11.167 25.4025 11.0262C25.6078 10.8851 25.7037 10.6425 25.6478 10.4085L24.8844 7.21831L27.4734 5.0648C27.6638 4.90676 27.7365 4.65717 27.6584 4.42879Z" fill="#FEC031"></path>
                                        <path d="M43.4816 4.42879C43.4039 4.20084 43.1908 4.03894 42.9386 4.01738L39.5135 3.72235L38.1591 0.715105C38.0592 0.494713 37.8318 0.352051 37.5791 0.352051C37.3264 0.352051 37.0989 0.494713 36.9991 0.715621L35.6447 3.72235L32.219 4.01738C31.9673 4.03945 31.7547 4.20084 31.6766 4.42879C31.5984 4.65674 31.6706 4.90676 31.861 5.06437L34.45 7.21831L33.6866 10.4085C33.6307 10.6431 33.7267 10.8855 33.9318 11.0262C34.0421 11.1018 34.1711 11.1403 34.3012 11.1403C34.4134 11.1403 34.5247 11.1116 34.6246 11.0549L37.5791 9.3798L40.5325 11.0549C40.7486 11.1782 41.0211 11.167 41.2258 11.0262C41.431 10.8851 41.5269 10.6425 41.4711 10.4085L40.7076 7.21831L43.2966 5.0648C43.487 4.90676 43.5597 4.65717 43.4816 4.42879Z" fill="#FEC031"></path>
                                        <path d="M59.3039 4.42879C59.2262 4.20084 59.013 4.03894 58.7609 4.01738L55.3357 3.72235L53.9813 0.715105C53.8815 0.494713 53.654 0.352051 53.4013 0.352051C53.1486 0.352051 52.9212 0.494713 52.8213 0.715621L51.4669 3.72235L48.0413 4.01738C47.7896 4.03945 47.577 4.20084 47.4988 4.42879C47.4207 4.65674 47.4929 4.90676 47.6833 5.06437L50.2723 7.21831L49.5088 10.4085C49.453 10.6431 49.5489 10.8855 49.7541 11.0262C49.8644 11.1018 49.9934 11.1403 50.1235 11.1403C50.2357 11.1403 50.347 11.1116 50.4468 11.0549L53.4013 9.3798L56.3548 11.0549C56.5709 11.1782 56.8433 11.167 57.048 11.0262C57.2533 10.8851 57.3492 10.6425 57.2933 10.4085L56.5299 7.21831L59.1189 5.0648C59.3093 4.90676 59.382 4.65717 59.3039 4.42879Z" fill="#FEC031"></path>
                                        <path d="M75.1271 4.42879C75.0494 4.20084 74.8363 4.03894 74.5841 4.01738L71.159 3.72235L69.8046 0.715105C69.7047 0.494713 69.4773 0.352051 69.2246 0.352051C68.9719 0.352051 68.7444 0.494713 68.6446 0.715621L67.2902 3.72235L63.8645 4.01738C63.6128 4.03945 63.4002 4.20084 63.3221 4.42879C63.2439 4.65674 63.3161 4.90676 63.5065 5.06437L66.0955 7.21831L65.3321 10.4085C65.2762 10.6431 65.3722 10.8855 65.5773 11.0262C65.6876 11.1018 65.8166 11.1403 65.9467 11.1403C66.0589 11.1403 66.1702 11.1116 66.2701 11.0549L69.2246 9.3798L72.178 11.0549C72.3941 11.1782 72.6666 11.167 72.8713 11.0262C73.0765 10.8851 73.1724 10.6425 73.1166 10.4085L72.3531 7.21831L74.9421 5.0648C75.1325 4.90676 75.2052 4.65717 75.1271 4.42879Z" fill="#FEC031"></path>
                                    </svg>
                                </div>
                            </div>

                            <div className='mx-2'>
                                <img src={require('./Assets/Sailor.png')} className='review_img' />
                            </div>
                        </div>
                    </div>

                    <div className='results_card'>
                        <div class="w-embed">
                            <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 17.3245C0 21.1391 2.9404 24 6.6755 24C10.4106 24 13.351 21.1391 13.351 17.3245C13.351 14.702 11.9205 12.4768 9.77484 11.4437L14.2252 0H7.94702L2.06623 11.4437C0.715232 14.0662 0 15.7351 0 17.3245ZM17.0066 17.3245C17.0066 21.1391 19.947 24 23.6821 24C27.4967 24 30.3576 21.1391 30.3576 17.3245C30.3576 14.702 28.9272 12.4768 26.7815 11.4437L31.2318 0H24.9536L19.0729 11.4437C17.7219 14.0662 17.0066 15.7351 17.0066 17.3245Z" fill="#97532e"></path>
                            </svg>
                        </div>
                        <p>
                            So the course is just as great as dhruvee rather himself, I like how the way he explain the things by showing his own life experiences and yeah that will help me a lot for sure, mainly the day 3 and 4 part will help me beat the procrastination
                        </p>

                        <div className='d-flex align-items-center'>
                            <div>
                                <p className='text-weight-bold text-size-medium'>
                                    Hardeep S.
                                </p>

                                <div class="w-embed">
                                    <svg width="76" height="12" viewBox="0 0 76 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.8361 4.42891C11.7584 4.20096 11.5453 4.03906 11.2931 4.0175L7.86797 3.72247L6.51357 0.715227C6.41371 0.494835 6.18627 0.352173 5.93357 0.352173C5.68087 0.352173 5.45343 0.494835 5.35357 0.715743L3.99917 3.72247L0.573482 4.0175C0.321779 4.03958 0.10919 4.20096 0.0310539 4.42891C-0.0470826 4.65686 0.0250782 4.90689 0.215485 5.06449L2.80449 7.21843L2.04105 10.4086C1.98519 10.6432 2.08116 10.8856 2.28633 11.0263C2.39661 11.1019 2.52563 11.1404 2.65573 11.1404C2.76791 11.1404 2.87919 11.1117 2.97905 11.055L5.93357 9.37992L8.887 11.055C9.10312 11.1784 9.37556 11.1671 9.58027 11.0263C9.78553 10.8852 9.88141 10.6427 9.82554 10.4086L9.06211 7.21843L11.6511 5.06492C11.8415 4.90689 11.9142 4.65729 11.8361 4.42891Z" fill="#FEC031"></path>
                                        <path d="M27.6584 4.42879C27.5807 4.20084 27.3675 4.03894 27.1154 4.01738L23.6902 3.72235L22.3358 0.715105C22.236 0.494713 22.0085 0.352051 21.7558 0.352051C21.5031 0.352051 21.2757 0.494713 21.1758 0.715621L19.8214 3.72235L16.3957 4.01738C16.144 4.03945 15.9315 4.20084 15.8533 4.42879C15.7752 4.65674 15.8473 4.90676 16.0378 5.06437L18.6268 7.21831L17.8633 10.4085C17.8075 10.6431 17.9034 10.8855 18.1086 11.0262C18.2189 11.1018 18.3479 11.1403 18.478 11.1403C18.5902 11.1403 18.7015 11.1116 18.8013 11.0549L21.7558 9.3798L24.7093 11.0549C24.9254 11.1782 25.1978 11.167 25.4025 11.0262C25.6078 10.8851 25.7037 10.6425 25.6478 10.4085L24.8844 7.21831L27.4734 5.0648C27.6638 4.90676 27.7365 4.65717 27.6584 4.42879Z" fill="#FEC031"></path>
                                        <path d="M43.4816 4.42879C43.4039 4.20084 43.1908 4.03894 42.9386 4.01738L39.5135 3.72235L38.1591 0.715105C38.0592 0.494713 37.8318 0.352051 37.5791 0.352051C37.3264 0.352051 37.0989 0.494713 36.9991 0.715621L35.6447 3.72235L32.219 4.01738C31.9673 4.03945 31.7547 4.20084 31.6766 4.42879C31.5984 4.65674 31.6706 4.90676 31.861 5.06437L34.45 7.21831L33.6866 10.4085C33.6307 10.6431 33.7267 10.8855 33.9318 11.0262C34.0421 11.1018 34.1711 11.1403 34.3012 11.1403C34.4134 11.1403 34.5247 11.1116 34.6246 11.0549L37.5791 9.3798L40.5325 11.0549C40.7486 11.1782 41.0211 11.167 41.2258 11.0262C41.431 10.8851 41.5269 10.6425 41.4711 10.4085L40.7076 7.21831L43.2966 5.0648C43.487 4.90676 43.5597 4.65717 43.4816 4.42879Z" fill="#FEC031"></path>
                                        <path d="M59.3039 4.42879C59.2262 4.20084 59.013 4.03894 58.7609 4.01738L55.3357 3.72235L53.9813 0.715105C53.8815 0.494713 53.654 0.352051 53.4013 0.352051C53.1486 0.352051 52.9212 0.494713 52.8213 0.715621L51.4669 3.72235L48.0413 4.01738C47.7896 4.03945 47.577 4.20084 47.4988 4.42879C47.4207 4.65674 47.4929 4.90676 47.6833 5.06437L50.2723 7.21831L49.5088 10.4085C49.453 10.6431 49.5489 10.8855 49.7541 11.0262C49.8644 11.1018 49.9934 11.1403 50.1235 11.1403C50.2357 11.1403 50.347 11.1116 50.4468 11.0549L53.4013 9.3798L56.3548 11.0549C56.5709 11.1782 56.8433 11.167 57.048 11.0262C57.2533 10.8851 57.3492 10.6425 57.2933 10.4085L56.5299 7.21831L59.1189 5.0648C59.3093 4.90676 59.382 4.65717 59.3039 4.42879Z" fill="#FEC031"></path>
                                        <path d="M75.1271 4.42879C75.0494 4.20084 74.8363 4.03894 74.5841 4.01738L71.159 3.72235L69.8046 0.715105C69.7047 0.494713 69.4773 0.352051 69.2246 0.352051C68.9719 0.352051 68.7444 0.494713 68.6446 0.715621L67.2902 3.72235L63.8645 4.01738C63.6128 4.03945 63.4002 4.20084 63.3221 4.42879C63.2439 4.65674 63.3161 4.90676 63.5065 5.06437L66.0955 7.21831L65.3321 10.4085C65.2762 10.6431 65.3722 10.8855 65.5773 11.0262C65.6876 11.1018 65.8166 11.1403 65.9467 11.1403C66.0589 11.1403 66.1702 11.1116 66.2701 11.0549L69.2246 9.3798L72.178 11.0549C72.3941 11.1782 72.6666 11.167 72.8713 11.0262C73.0765 10.8851 73.1724 10.6425 73.1166 10.4085L72.3531 7.21831L74.9421 5.0648C75.1325 4.90676 75.2052 4.65717 75.1271 4.42879Z" fill="#FEC031"></path>
                                    </svg>
                                </div>
                            </div>

                            <div className='mx-2'>
                                <img src={require('./Assets/Sailor.png')} className='review_img' />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
